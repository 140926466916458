body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursorPointer {
  cursor: pointer;
}

.bg-custom {
  background-color: #7f757d;
}
.bg-custom-light {
  background-color: #f5eff3;
}
.btn-bg-custom {
  border: 2px solid #cbc5ca;
  border-radius: 5px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  /* padding: 3px; */
  /* padding-left: 3px;
  padding-right: 3px; */
  background-color: #7f757d;
  color: aliceblue;
  /* --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;*/
  font-size: 0.8rem;
}
.btn-bg-custom:hover {
  background-color: #90858d;
  border: 2px solid #90858d;
  color: aliceblue;
  box-shadow: inset;
}
.link-btn-bg-custom {
  text-decoration: none;
  padding: 3px;
  font-size:0.8rem;
  background-color: #7f757d;
  border: 2px solid #7f757d;
  color: aliceblue;
  box-shadow: inset;
}
.link-btn-bg-custom:hover {
  text-decoration: none;
  background-color: #90858d;
  border: 2px solid #90858d;
  color: aliceblue;
  box-shadow: inset;
}
.custom-text{
font-family: serif;
/* font-size: 2vw; */
}

.custom-shadow {
  box-shadow: 0px 2px 15px 5px #d0c6cc;
}

.custom-link{
  color:#7f757d;
  text-decoration: none;
}
.custom-link:hover{
  color:#4e4b4d;
  text-decoration: none;
}

/* style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"> */
